import React from "react";
import StudentSubjectAnalysis from "./StudentSubjectAnalysis";
import TopicAccordion from "./TopicAccordion";
import warning_icon from "../../../images/users-2.png";

function StudentMasteryLevelTabBody(props) {
  return (
    <div>
      <StudentSubjectAnalysis StudentParticipationRate={props.masteryLevels.subject_analysis ? props.masteryLevels.subject_analysis : 0} />
      <button className="ml-[5px] mt-[2px] flex pt-4 text-[16px] font-bold">
        Topic Level Mastery
        <img className="ml-2 h-[20px] w-[20px] mt-1" src={warning_icon} alt="Warning icon" />
      </button>
      {props.masteryLevels.topic_analysis?.length > 0 ? (
        <TopicAccordion topicData={props.masteryLevels.topic_analysis} />
      ) : (
        <div className="mt-[2%] text-center">Data will be available once you start attempting the quizzes.</div>
      )}
    </div>
  );
}

export default StudentMasteryLevelTabBody;
