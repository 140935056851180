import React from "react";

function DashboardFoldersContainer(props) {
  var foldersList = props.folders_list;
  // const navigate = useNavigate();

  // const handleViewResultClick = (quiz) => {
  //  amplitude.getInstance().logEvent('View Result');
  //   navigate(`/view-result?id=${quiz.secret_key}`, {
  //     state: { secret_key: quiz.secret_key, quiz_title: quiz.quiz_title, created_timestamp: quiz.created_timestamp, quiz_total_marks: quiz.quiz_total_marks, quiz_duration: quiz.quiz_duration },
  //   });
  // };
  // const handleViewQuizClick = (e) => {
  //   var secret_key = e.target.value
  //   amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
  //   amplitude.getInstance().logEvent('ViewQuiz', "user has clicked on view quiz button");
  //  // var secret_key = document.getElementById("button").value;
  //  // var quiz_title = document.getElementById("button").name;
  //   navigate(`/add-details?id=${secret_key}`);
  // };
  return (
    <>
    {foldersList.length === 0 ? (
      <label className="no-quiz-label justify-center">
        You haven't created any folder yet.
      </label>
    ) : (
    <div className="flex flex-col items-center">
      <div className="grid xlg:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-2 mt-0  w-full">
        {foldersList.map((folder) => (
          <div className="quiz-div w-full" onClick={()=>{props.onFolderClick(folder);}}>
            <div className="inner-my-quizzes">
              <div className="dashboard-my-quizzes-name text-with-dots">{folder.folder_name}</div>
            </div>
            <div>
            <div className="dashboard-my-quizzes-info ">
            {folder.description}
            </div>
            
            </div>
          </div>
        ))}
      </div>
    </div>
      )}
      </>
  );
}

export default DashboardFoldersContainer;
