import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import success from "../../../images/payment_done.png";
import back from "../../../images/arrow_back_stripe.png";
import organizatin from "../../../images/oraganization.png";
import axios from "axios";
const Confirm = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmiting] = useState(false);
  const [promocodeValidationStatus, setPromocodeValidationStatus] = useState();
  const storedState = sessionStorage.getItem("checkoutState");
  let data = JSON.parse(storedState);
  var discount = (data?.vouchersRate * 20) / 100;
  const confirmed = async () => {
    const amount2 = parseInt(localStorage.getItem("amount"));

    const currentDateTime = moment();

    let durationToAdd;
    if (amount2 === 48) {
      // Add one month for amount 48
      durationToAdd = 12;
    } else if (amount2 === 15) {
      // Add one year for amount 15
      durationToAdd = 3;
    } else if (amount2 === 8) {
      // Add three months for amount 8
      durationToAdd = 1;
    } else {
      // Default: Add one month for other amounts
      durationToAdd = 1;
    }

    // Add the determined duration to the current date and time
    const oneMonthLater = currentDateTime.add(durationToAdd, "months");

    const formattedDateTime = oneMonthLater.format("Y-MM-DD HH:mm:ss");

    // Set the state with the formatted date and time

    // Payment was successful, call your additional API with access token
    const token = localStorage.getItem("access_token"); // Retrieve the access token
    const mail = localStorage.getItem("email_address");
    const trial_id = localStorage.getItem("trial_id");
    const amount = localStorage.getItem("amount");
    discount = (parseFloat(amount) * 20) / 100;
    const subject_id = localStorage.getItem("subject_id");
    const subject_name = localStorage.getItem("subject_name");
    if (trial_id === "upgrade") {
      const response = await fetch(`${process.env.REACT_APP_REST_API_BASE_URL}/start_subscription`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the access token in the Authorization header
        },
        body: JSON.stringify({
          current_date: moment().format("Y-MM-DD HH:mm:ss"),
          end_date: formattedDateTime,
          subject_id: subject_id,
          subject_name: subject_name,
          amount_paid: parseFloat(amount) + discount,
          email: mail,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to call the additional API");
      }

      // Additional API call was successful
      // Perform any necessary actions here
      // setIsSubmiting(false);
      console.log("Additional API call successful");
      // navigate("/");
    } else {
      const response = await fetch(`${process.env.REACT_APP_REST_API_BASE_URL}/submit_subscriptions_details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`, // Include the access token in the Authorization header
        },
        body: JSON.stringify({
          subscription_start: moment().format("Y-MM-DD HH:mm:ss"),
          subscription_end: formattedDateTime,
          trial_period_id: trial_id,
          amount_paid: parseFloat(amount) + discount,
          email: mail,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to call the additional API");
      }

      // Additional API call was successful
      // Perform any necessary actions here
      console.log("Additional API call successful");
    }
  };
  const handleBack = (e) => {
    e.preventDefault();
    navigate("/");
  };

  const handleUpdateOrder = () => {
    let mail = localStorage.getItem("email_address");
    let subscription_id = localStorage.getItem("subscription_id");
    const currentDate = moment();
    const futureDate = currentDate.add(
      process.env.REACT_APP_SUBSCRIPTION_TIME,
      data.plan === "Year" ? process.env.REACT_APP_SUBSCRIPTION_UNIT : process.env.REACT_APP_SUBSCRIPTION_UNIT_MONTH
    ); // Adding 1 hour
    const expiryDate = futureDate.format("Y-MM-DD HH:mm:ss");
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_subscription", {
        method: "POST",
        subscription_id: subscription_id,
        additional_amount: parseFloat(data.vouchersRate) + parseFloat(discount),
      })
      .then((response) => {
        console.log(response);
        // navigate("/subscription");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleClickPlaceOrder = () => {
    let mail = localStorage.getItem("email_address");

    const currentDate = moment();
    const futureDate = currentDate.add(
      process.env.REACT_APP_SUBSCRIPTION_TIME,
      data.plan === "Year" ? process.env.REACT_APP_SUBSCRIPTION_UNIT : process.env.REACT_APP_SUBSCRIPTION_UNIT_MONTH
    ); // Adding 1 hour
    const expiryDate = futureDate.format("Y-MM-DD HH:mm:ss");
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/buy_subject", {
        method: "POST",
        trial_period_tracking_id: data.subject.trial_period_tracking_id,
        order_start_date: moment().format("Y-MM-DD HH:mm:ss"),
        order_end_date: expiryDate,
        purchase_verification_status: 1,
        voucher_bought: data.noOfStudent,
        purchase_key: null,
        first_name: "",
        last_name: "",
        school: "",
        country: "",
        town_city: "",
        post_code: "",
        street_address: "",
        email: mail,
        phone: "",
        comment: "",
        bill_paid: data.vouchersRate,
        promocode_validation_status: promocodeValidationStatus
          ? promocodeValidationStatus
          : {
              discount_percentage: 0,
              promocode_id: null,
              redeemed_status: 0,
            },
      })
      .then((response) => {
        console.log(response);
        sessionStorage.clear();
        // navigate("/subscription");
      })
      .catch((error) => {
        console.log(error);
        sessionStorage.clear();
      });
  };
  useEffect(() => {
    if (data === null) {
      navigate("/subscription");
      return;
    }
    console.log("data is ", data?.is_stripe_active);
    if (data?.is_stripe_active === 1) {
      handleUpdateOrder();
      handleClickPlaceOrder();
    } else {
      handleClickPlaceOrder();
    }
  }, []);

  return (
    <div className="flex flex-col md:flex-row bg-gray-50">
      {/* Left side */}
      <div className="md:w-1/2 h-screen md:pt-[120px] bg-[#282C4D] text-white py-8 px-4 md:px-[102px] flex flex-col items-center justify-center md:justify-start">
        {/* Back navigation arrow */}
        {/* Company name */}
        <div className="text-center md:text-left pl-[25%] pr-[25%]">
          <h1 className="text-2xl mb-[37px] font-semibold flex items-center ">
            <button onClick={() => navigate("/subscription")} className="mr-4 text-gray-400">
              {/* Proper left arrow */}
              <img src={back} className="h-[16px] w-[16px]" />
            </button>
            <img src={organizatin} className="h-[32px] w-[32px] mr-2" />
            Teepee{" "}
          </h1>
          {/* Subscribe to subject name */}
          <p className="text-lg font-semibold text-gray-400 text-start">Subscribe to {data?.subjectName} </p>
          {/* Yearly subscription */}
          <div className="rounded-lg flex text-black">
            <span className="text-[48px] font-bold text-white" style={{ lineHeight: "56px", fontFamily: "Roboto" }}>
              £{parseFloat(data?.vouchersRate) + parseFloat(discount)}
            </span>
            <div className="flex flex-col items-end mt-[10px] ml-1">
              <span
                className="text-[14px] font-semibold text-gray-400 "
                style={{
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                  fontFamily: "Roboto",
                }}
              >
                every
              </span>
              <span
                className="text-[14px] text-gray-400"
                style={{
                  lineHeight: "20px",
                  letterSpacing: "0.1px",
                  fontFamily: "Roboto",
                }}
              >
                {data?.plan === "Year" ? "Year" : "Month"}
              </span>
            </div>
          </div>
          {/* Paragraph related to subscription */}
          <p className="text-gray-400 text-start">
            Boost your student learning with automated & personalised spaced retrieval practice and exam-style question practice, featuring instant AI
            marking feedback.
          </p>
        </div>
      </div>

      {/* Right side */}
      <div className="md:w-1/2 bg-white py-8 px-4 md:p-[102px] flex flex-col items-center gap-2 justify-center">
        {/* Subscription details */}
        <img src={success} alt="Success" />
        <h1
          className="font-semibold text-center"
          style={{
            fontFamily: "Roboto",
            fontSize: "24px",
            lineHeight: "32px",
            color: "#322E33",
          }}
        >
          Your upgrade was successful
        </h1>{" "}
        <p className="text-gray-700 mb-4 text-center">
          Thank you for subscribing to Teepee.ai! Your access to {data?.subjectName} Science has<br></br> been activated.
        </p>
        {/* Back to Home button */}
        <button
          onClick={(e) => handleBack(e)}
          className="bg-[#444780] text-white font-semibold py-3 px-8 rounded-lg inline-flex items-center justify-center"
          disabled={isSubmitting}
        >
          <span style={{ fontFamily: "Roboto" }} className="inline">
            Back to Home
          </span>
          {isSubmitting && (
            <svg className="animate-spin ml-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          )}
        </button>
      </div>
    </div>
  );
};

export default Confirm;
