import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import UpgradePlan from "../../Subscription/UpgradePlan";

const AccountStatus = {
  TRIAL_ACTIVE: 1,
  TRIAL_EXPIRED: 2,
  SUBSCRIPTION_ACTIVE: 3,
  SUBSCRIPTION_EXPIRED: 4,
  SPECIAL_CONDITION: 5, // For complex status conditions
};

function getBackgroundColor(accountStatus, daysLeft, subscriptionDaysLeft) {
  if (accountStatus === AccountStatus.TRIAL_ACTIVE || (accountStatus === AccountStatus.SPECIAL_CONDITION && !subscriptionDaysLeft && daysLeft > 0)) {
    return "bg-[#6750A4]"; // Purple
  } else if (
    accountStatus === AccountStatus.TRIAL_EXPIRED ||
    (!subscriptionDaysLeft && accountStatus === AccountStatus.SPECIAL_CONDITION && daysLeft <= 0) ||
    accountStatus === AccountStatus.SUBSCRIPTION_EXPIRED
  ) {
    return "bg-[#F2B8B5]"; // Light Red
  } else if (
    accountStatus === AccountStatus.SUBSCRIPTION_ACTIVE ||
    (subscriptionDaysLeft && accountStatus === AccountStatus.SPECIAL_CONDITION && subscriptionDaysLeft > 0)
  ) {
    return "bg-[#D0BCFF]"; // Light Purple
  }
  return ""; // Default background color if none of the conditions match
}

function getTextColor(accountStatus, daysLeft, subscriptionDaysLeft) {
  if (accountStatus === AccountStatus.TRIAL_ACTIVE || (accountStatus === AccountStatus.SPECIAL_CONDITION && !subscriptionDaysLeft && daysLeft > 0)) {
    return "text-white";
  } else if (
    accountStatus === AccountStatus.TRIAL_EXPIRED ||
    (!subscriptionDaysLeft && accountStatus === AccountStatus.SPECIAL_CONDITION && daysLeft <= 0) ||
    accountStatus === AccountStatus.SUBSCRIPTION_EXPIRED
  ) {
    return "text-[#DC362E]"; // Red
  } else if (
    accountStatus === AccountStatus.SUBSCRIPTION_ACTIVE ||
    (subscriptionDaysLeft && accountStatus === AccountStatus.SPECIAL_CONDITION && subscriptionDaysLeft > 0)
  ) {
    return "text-[#21005D] font-medium"; // Dark Purple
  }
  return ""; // Default text color if none of the conditions match
}

function SubscriptionExpirationBanner({ bannerObject, subjectData, setOpen }) {
  const navigate = useNavigate();
  const user_type = localStorage.getItem("user_type");
  const navigateToSubscription = () => {
    setOpen(true);
  };

  const handleTotalNumberOfQuizzes = () => {
    if (localStorage.getItem("email_address") === process.env.REACT_APP_REQUESTED_USER && subjectData.subject_id === 10) {
      return 20;
    } else {
      return process.env.REACT_APP_TRIAL_QUIZ;
    }
  };

  const backgroundColor = getBackgroundColor(bannerObject.account_status, bannerObject.days_left, bannerObject.subscription_days_left);
  const textColor = getTextColor(bannerObject.account_status, bannerObject.days_left, bannerObject.subscription_days_left);

  let message;
  if (
    bannerObject.account_status === AccountStatus.TRIAL_ACTIVE ||
    (bannerObject.account_status === AccountStatus.SPECIAL_CONDITION && !bannerObject.subscription_days_left && bannerObject.days_left > 0)
  ) {
    message = `Your trial for ${subjectData.subject_name} will expire in ${bannerObject.days_left} days.`;
  } else if (
    bannerObject.account_status === AccountStatus.TRIAL_EXPIRED ||
    (!bannerObject.subscription_days_left && bannerObject.account_status === AccountStatus.SPECIAL_CONDITION && bannerObject.days_left <= 0)
  ) {
    message = `Your trial for ${subjectData.subject_name} has expired!`;
  } else if (bannerObject.account_status === AccountStatus.SUBSCRIPTION_EXPIRED) {
    message = `Your subscription for ${subjectData.subject_name} has expired!`;
  } else if (
    bannerObject.account_status === AccountStatus.SUBSCRIPTION_ACTIVE ||
    (bannerObject.subscription_days_left &&
      bannerObject.account_status === AccountStatus.SPECIAL_CONDITION &&
      bannerObject.subscription_days_left > 0)
  ) {
    message = subjectData.subject_name;
  }

  const quizzesLeft =
    bannerObject.account_status === AccountStatus.TRIAL_EXPIRED ||
    (!bannerObject.subscription_days_left && bannerObject.account_status === AccountStatus.SPECIAL_CONDITION && bannerObject.days_left <= 0) ||
    bannerObject.account_status === AccountStatus.SUBSCRIPTION_EXPIRED
      ? 0
      : bannerObject.account_status === 1 ||
        (!bannerObject.subscription_days_left && bannerObject.account_status === AccountStatus.SPECIAL_CONDITION && bannerObject.days_left > 0)
      ? handleTotalNumberOfQuizzes() - bannerObject.quiz_count
      : process.env.REACT_APP_SUBSCRIPTION_QUIZ - bannerObject.quiz_count_subscribed;

  const vouchersAvailable =
    bannerObject.account_status === AccountStatus.TRIAL_ACTIVE ||
    (!bannerObject.subscription_days_left && bannerObject.account_status === AccountStatus.SPECIAL_CONDITION && bannerObject.days_left > 0)
      ? "Unlimited (trial)"
      : (!bannerObject.subscription_days_left && bannerObject.account_status === AccountStatus.SPECIAL_CONDITION && bannerObject.days_left <= 0) ||
        (bannerObject.subscription_days_left &&
          bannerObject.account_status === AccountStatus.SPECIAL_CONDITION &&
          bannerObject.subscription_days_left <= 0) ||
        bannerObject.account_status === AccountStatus.SUBSCRIPTION_EXPIRED
      ? ""
      : bannerObject.vouchers_bought;

  const buttonText = bannerObject.account_status === AccountStatus.TRIAL_ACTIVE ? "Buy now" : "Upgrade";

  return (
    <div className={`w-full p-2  ${backgroundColor} `}>
      <div className="h-full  sm:grid sm:grid-cols-2 md:flex justify-center items-center sm:text-center">
        <div className={`${textColor} md:text-sm sm:text-[10px] md:ml-2 `}>{message}</div>
        <div
          className={`${textColor} md:text-sm sm:text-[10px] font-light md:ml-4`}
          style={{
            display:
              user_type === "2" ||
              bannerObject.account_status === AccountStatus.TRIAL_ACTIVE ||
              bannerObject.account_status === AccountStatus.TRIAL_EXPIRED
                ? ""
                : "none",
          }}
        >
          Tasks left: {quizzesLeft}
        </div>
        {/* <div className={`${textColor}  md:text-sm sm:text-[10px] font-bold md:ml-4`} style={{ display: user_type === "2" ? "" : "none" }}>
          Vouchers available: {vouchersAvailable}
        </div> */}
        <div>
          <button
            onClick={navigateToSubscription}
            className={`sm:w-[40%] md:w-auto ${
              backgroundColor === "bg-[#6750A4]" ? "bg-white  text-[#6750A4] " : "!bg-[#6750A4] text-white"
            }  rounded-2xl ml-4  md:text-sm sm:text-[10px] font-medium mr-2`}
            style={{ padding: "2px 15px" }}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionExpirationBanner;
