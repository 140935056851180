import React, { useState, useRef, useEffect } from "react";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import { Link } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
function ViewSubject() {
  const columns = [
    {
      subject_id: "subject_id",
      id: "subject_name",
      label: "Subject Name",
      status: "status",
      minWidth: 170,
      maxWidth: 200,
      align: "center",
    },
  ];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  const [rows, setRows] = useState([]);

  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();
  const userType = localStorage.getItem("user_type");

  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    if (userType === "1") {
      navigate("/view_subject");
      return;
    }
  }, [navigate, userType]);

  // const onChange = (e) => {
  //   setCreateUser({ ...createUser, [e.target.name]: e.target.value });
  // };

  const getSubjectsList = () => {
    axios
      .request({
        method: "GET",
        url: process.env.REACT_APP_REST_API_BASE_URL + "/get_subjects_for_admin",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((res) => {
        console.log("data: ", res.data);
        setRows(res.data);
      });
  };

  useEffect(() => {
    getSubjectsList();
  }, []);

  const isSubmitting = useRef(false);
  function submitForm() {
    var token = "Bearer " + localStorage.getItem("access_token");

    var subject_name = document.getElementById("subject_name").value;

    //const sub_topic_id_list = [1, 2]
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/create_new_subject", {
        method: "POST",
        subject_name: subject_name,
        has_sub_topics: checked,
      })

      .then((response) => {
        console.log(response);

        isSubmitting.current = false;

        alert("New subject created successfully");
        document.getElementById("subject_name").value = "";
        getSubjectsList();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleCloseWithCancel = () => {
    setOpen(false);
    isSubmitting.current = false;
  };

  const handleCloseWithYes = () => {
    setOpen(false);
    submitForm();
  };

  const handleSubmitOptionClick = (event) => {
    //setUserMessage(param1)
    //setPrompt(param2)
    var subject_name = document.getElementById("subject_name").value;

    if (subject_name.length > 0) {
    } else {
      alert("Please enter all details");
      // resolve();
      return;
    }
    setOpen(true);
  };

  const updateButtonStatus = (status, subject_id) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_subject_status", {
        method: "POST",
        subject_id: subject_id,
        status: status,
      })

      .then((response) => {
        alert("Subject Status changed");
        getSubjectsList();
      })
      .catch((error) => {
        console.log(error);
        alert("unable to update subject status");
      });
  };

  const updateTrialPeriodStatus = (status, subject_id) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_trial_period_status", {
        method: "POST",
        subject_id: subject_id,
        trial_period: status,
      })

      .then((response) => {
        alert("Subject Status changed");
        getSubjectsList();
      })
      .catch((error) => {
        console.log(error);
        alert("unable to update subject status");
      });
  };

  const updateExamPaperStatusButton = (status, subject_id) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_exam_paper_status", {
        method: "POST",
        subject_id: subject_id,
        status: status,
      })

      .then((response) => {
        alert("Exam Paper Status changed");
        getSubjectsList();
      })
      .catch((error) => {
        console.log(error);
        alert("unable to update subject status");
      });
  };
  const updateRubricAAnswerStatusButton = (status, subject_id) => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/update_anser_rubric_status", {
        method: "POST",
        subject_id: subject_id,
        show_answer_rubric: status,
      })

      .then((response) => {
        alert("Rubric  Answer Status changed");
        getSubjectsList();
      })
      .catch((error) => {
        console.log(error);
        alert("unable to update subject status");
      });
  };

  async function viewSubjectSetting(id) {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;

    try {
      const response = await axios.post(`${process.env.REACT_APP_REST_API_BASE_URL}/get_subjects_settings`, {
        method: "POST",
        subject_id: id,
      });

      console.log("DATA IN THE JSON2: ", response.data["subject"]);
      var subjects_list = response.data["subject"];

      console.log("EXAM STYLE: ", subjects_list);

      const sbj = subjects_list[0]?.subject_id;

      if (sbj === id) {
        navigate("/subject_settings?id=" + id);
      } else {
        try {
          await axios.post(`${process.env.REACT_APP_REST_API_BASE_URL}/create_subjects_settings`, {
            method: "POST",
            subject_id: id,
            r1: "",
            r2: "",
            r3: "",
            r2_days: "",
            r3_days: "",
            total_quiz_questions: 0,
            toggle_for_days: 0,
            toggle_button_day: 0,
            time: 1,
          });
          navigate("/subject_settings?id=" + id);
        } catch (error) {
          console.error(error);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleToggleChange = async (subjectId) => {
    const currentToggleState = rows.find((row) => row.subject_id === subjectId).subject_status_have_lesson;
    const newToggleState = currentToggleState === 1 ? 0 : 1;

    try {
      var token = "Bearer " + localStorage.getItem("access_token");
      const response = await axios.post(
        `${process.env.REACT_APP_REST_API_BASE_URL}/update_subject_have_lesson_status`,
        {
          subject_id: subjectId,
          subject_status_have_lesson: newToggleState,
        },
        {
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: token,
            "Access-Control-Allow-Origin": "*",
          },
        }
      );

      if (response.data) {
        setRows(rows.map((row) => (row.subject_id === subjectId ? { ...row, subject_status_have_lesson: newToggleState } : row)));
      } else {
        console.error("API call succeeded but failed to update status:", response.data.message);
      }
    } catch (error) {
      console.error("Failed to update subject status", error);
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleCloseWithCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <b>Confirm</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to create new subject?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithCancel}>NO</Button>
          <Button className="delete-button" onClick={handleCloseWithYes} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Sidebar item_id="view_subject" />
      <div className="sm:ml-[5px] sm:mr-[5px] md:ml-[316px] ">
        <div className="home sm:ml[25px] md:ml-[60px]">
          <div>
            <label className="dashboard-label">Add New Subject</label>
            <div className="teepee-divider"></div>
          </div>
        </div>
        <div className="flex flex-row md:ml-[60px] items-center ">
          <div className=""></div>
          <div className="selectionDiv sm:w-[100%] md:w-[600px]">
            <div className="form-div">
              <div className="md:w-[600px]">
                <div>
                  <label className="create_quiz_label">Enter subject name</label>
                </div>
                <div>
                  <input type="text" name="subject_name" id="subject_name" className="teepee-input" placeholder="" required />
                </div>
                {/* <div>
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <label className="create_quiz_label">Has subtopics</label>
                </div> */}

                <button
                  onClick={handleSubmitOptionClick}
                  disabled={isSubmitting.current}
                  className="teepee-button mt-[10px] hover:bg-black"
                  style={{ width: "46%" }}
                >
                  {isSubmitting.current && (
                    <div className="spinner-border spinner-border-m float-left" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-left items-center sm:ml-[10px] md:ml-[60px] mt-[20px] sm:mr-[10px] md:mr-[60px]">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 840 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: "#f7f2f9",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell key={"prompt"} align={"center"} style={{ minWidth: "50px", backgroundColor: "#f7f2f9" }}>
                      Prompt
                    </TableCell>
                    <TableCell key={"prompt"} align={"center"} style={{ minWidth: "50px", backgroundColor: "#f7f2f9" }}>
                      Settings
                    </TableCell>
                    <TableCell key={"action"} align={"center"} style={{ minWidth: "50px", backgroundColor: "#f7f2f9" }}>
                      Action
                    </TableCell>
                    <TableCell
                      key={"action4"}
                      align={"right"}
                      style={{
                        minWidth: "50px",
                        backgroundColor: "#f7f2f9",
                        textAlign: "center",
                        // fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      Lessons/Concepts Action
                    </TableCell>
                    <TableCell
                      key={"action5"}
                      align={""}
                      style={{
                        minWidth: "50px",
                        backgroundColor: "#f7f2f9",
                        textAlign: "center",
                        // fontWeight: "bold",
                        fontFamily: "Roboto",
                      }}
                    >
                      Hide Rubric Answer
                    </TableCell>
                    {/* <TableCell
                      key={"action2"}
                      align={"right"}
                      style={{ minWidth: "50px", backgroundColor: "#f7f2f9" }}
                    >
                      Trial Status
                    </TableCell> */}
                    <TableCell key={"action2"} align={"right"} style={{ minWidth: "50px", backgroundColor: "#f7f2f9" }}>
                      Exam Paper Status
                    </TableCell>
                    <TableCell key={"action2"} align={"right"} style={{ minWidth: "50px", backgroundColor: "#f7f2f9" }}>
                      Status
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    console.log("ROW DATA: ", row);
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} id={row["subject_id"]} key={row.code}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              style={{
                                minWidth: column.minWidth,
                                maxWidth: column.maxWidth,
                                overflowWrap: "break-word",
                                borderLeft: "1px solid rgba(224, 224, 224, 1)",
                              }}
                              key={column.id}
                              align={"left"}
                            >
                              {column.format && typeof value === "number" ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                        <TableCell key={"prompt"} align={"center"} style={{ minWidth: "50px" }}>
                          <a href={"/subject_prompt?id=" + row["subject_id"]} className="active">
                            View/Edit Prompt{" "}
                          </a>
                        </TableCell>
                        <TableCell align={"center"} style={{ minWidth: "50px" }}>
                          <button
                            style={{
                              // color: "#0000EE",
                              textDecoration: "underline",
                              fontWeight: 50,
                            }}
                            onClick={() => viewSubjectSetting(row["subject_id"])}
                          >
                            <a className="active">R1, R2, R3 settings</a>
                          </button>
                        </TableCell>

                        <TableCell
                          align={"center"}
                          style={{ minWidth: "50px" }}
                          onClick={() => {
                            localStorage.setItem("lesson_status", row.subject_status_have_lesson);
                            navigate(`/view_subject_content?id=${row["subject_id"]}`);
                          }}
                        >
                          <button
                            style={{
                              color: "#0000EE",
                              textDecoration: "underline",
                              fontWeight: 50,
                            }}
                            className="active"
                          >
                            View/Edit Content
                          </button>
                        </TableCell>
                        <TableCell align={"right"} style={{ minWidth: "50px", textAlign: "center" }}>
                          <label className="relative inline-block w-14 h-8">
                            <input
                              type="checkbox"
                              className="opacity-0 w-0 h-0"
                              checked={row.subject_status_have_lesson || false}
                              onChange={() => handleToggleChange(row["subject_id"])}
                            />
                            <span
                              className={`absolute cursor-pointer inset-0 transition-all duration-300 rounded-full ${
                                row.subject_status_have_lesson ? "bg-purple-800" : "bg-gray-300"
                              }`}
                              style={{
                                backgroundColor: row.subject_status_have_lesson ? "#7E418B" : undefined,
                              }}
                            >
                              <span
                                className={`absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition-transform ${
                                  row.subject_status_have_lesson ? "translate-x-6" : "translate-x-0"
                                }`}
                              ></span>
                            </span>
                          </label>
                        </TableCell>
                        <TableCell key={"action5"} align={""} style={{ minWidth: "50px" }}>
                          {row["show_answer_rubric"] === 0 && (
                            <button onClick={() => updateRubricAAnswerStatusButton(1, row["subject_id"])} className="btn btn-dark">
                              Turn On
                            </button>
                          )}
                          {row["show_answer_rubric"] === 1 && (
                            <button onClick={() => updateRubricAAnswerStatusButton(0, row["subject_id"])} className="btn btn-dark">
                              Turn Off
                            </button>
                          )}
                        </TableCell>

                        {/* <TableCell
                            key={"action2"}
                            align={"right"}
                            style={{ minWidth: "50px" }}
                          >
                            {row["trial_period"] === 0 && (
                              <button
                                onClick={() =>
                                  updateTrialPeriodStatus(1, row["subject_id"])
                                }
                                className="btn btn-danger"
                              >
                                Activate
                              </button>
                            )}
                            {row["trial_period"] === 1 && (
                              <button
                                onClick={() =>
                                  updateTrialPeriodStatus(0, row["subject_id"])
                                }
                                className="btn btn-danger"
                              >
                                De-Activate
                              </button>
                            )}
                          </TableCell> */}

                        <TableCell key={"action2"} align={"right"} style={{ minWidth: "50px" }}>
                          {row["exam_paper_quiz_status"] === 0 && (
                            <button onClick={() => updateExamPaperStatusButton(1, row["subject_id"])} className="btn btn-dark">
                              Turn On
                            </button>
                          )}
                          {row["exam_paper_quiz_status"] === 1 && (
                            <button onClick={() => updateExamPaperStatusButton(0, row["subject_id"])} className="btn btn-dark">
                              Turn Off
                            </button>
                          )}
                        </TableCell>

                        <TableCell key={"action2"} align={"right"} style={{ minWidth: "50px" }}>
                          {row["status"] === 2 && (
                            <button onClick={() => updateButtonStatus(1, row["subject_id"])} className="btn btn-danger">
                              Activate
                            </button>
                          )}
                          {row["status"] === 1 && (
                            <button onClick={() => updateButtonStatus(2, row["subject_id"])} className="btn btn-danger">
                              De-Activate
                            </button>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              style={{ backgroundColor: "#f7f2f9" }}
              rowsPerPageOptions={[100, 150, 200]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </div>
    </>
  );
}

export default ViewSubject;
