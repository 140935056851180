import React from "react";
import { MDBFooter, MDBContainer, MDBCol, MDBRow } from "mdb-react-ui-kit";

export default function Footer() {
  return (
    <MDBFooter
      className="text-left text-white mb-0 ml-20"
      style={{ backgroundColor: "white" }}
    >
      <MDBContainer className="pb-1" style={{ float: "left" }}>
        <MDBRow className="h-0">
          <MDBCol lg="3" className="mb-4 mb-md-0 w-auto">
            <div style={{ color: "black" }}>
              © Teepee.ai. All rights reserved
            </div>
          </MDBCol>
          <MDBCol lg="1" className="mb-4 mb-md-0">
            <div style={{ color: "black" }}>
              <a
                href="/about_us"
                target="_blank"
                rel="noopener noreferrer"
                className="text-black"
              >
                About
              </a>
            </div>
          </MDBCol>
          <MDBCol lg="3" className="mb-4 mb-md-0">
            <div style={{ color: "black" }}>
              <a href="/privacy-policy.html" className="text-black">
                Privacy Policy
              </a>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBFooter>
  );
}
