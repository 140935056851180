import React, { useContext, useState } from "react";
import Copy from "../../../images/copy.svg";
import amplitude from "amplitude-js";

function ClassroomDetailBanner(props) {
  const classroomData = props.classroomData;
  const subjectName = classroomData.subject ? classroomData.subject.subject_name : "";
  return (
    <div className="home sm:ml[25px] md:ml-[14px] bg-[#F7F2F9] h-[93px] ">
      <div className="w-[50%] md:p-[15px] md:pl-[2.8rem]">
        <div
          className="classroom_name_div "
          style={{
            fontFamily: "roboto",
            fontWeight: "normal",
            fontSize: "22px",
          }}
        >
          {classroomData.classroom_name}
        </div>
        <div className="classroom_details_label">Enrolled Students :{" " + classroomData.classroom_enrolled_students}</div>
      </div>
      <div className="w-[50%] self-center">
        <div className="classroom_name_div ">
          <div className="classroom_details_label flex sm:!overflow-auto">
            Class code : {" " + classroomData.classroom_code}
            <button
              onClick={() => {
                amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
                amplitude.getInstance().logEvent("CopyClassCode");
                navigator.clipboard.writeText(props.classroomData.classroom_code);
                navigator.clipboard.writeText(classroomData.classroom_code);
                alert("Class code has been copied");
              }}
            >
              <img src={Copy} className="w-[15px] ml-[10px]"></img>
            </button>
          </div>
        </div>
        <div className="classroom_details_label">Subject : {" " + subjectName}</div>
      </div>
    </div>
  );
}

export default ClassroomDetailBanner;
