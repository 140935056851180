import React, { useContext, useState } from "react";
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from "@material-tailwind/react";
import TeacherDashboardTabBody from "./TeacherDashboardTabBody";
import TeacherStudentTabBody from "./widgets/TeacherStudentTabBody";
import TeacherMarkbookTabBody from "./TeacherMarkbookTabBody";
import TeacherAnalyticsTabBody from "./TeacherAnalyticsTabBody";
import appContext from "../../context/appContext";
import DashboardIcon from "../../images/Lightbulb.png";
import StudentIcon from "../../images/personicon.png";
import MarkbookIcon from "../../images/cognition.png";
import Analytics from "../../images/analytics.png";
import amplitude from "amplitude-js";
import TeacherRetrievalTabBody from "./widgets/TeacherRetrievalTabBody";
import TeacherTable from "./widgets/TeacherTable";

function TeacherHomeTabs(props) {
  const state = useContext(appContext);

  // State to manage sub-tabs within the "Manage" tab (Students/Teachers)
  const [manageSubTab, setManageSubTab] = useState("student");

  const data = [
    {
      label: "Tasks",
      value: 1,
      body: (
        <>
          {state.DashboardTabsState === 1 ? (
            <TeacherDashboardTabBody
              classroomData={props.classroomData}
              getClassData={props.getClassData}
              bannerObject={props.bannerObject}
              subjectData={props.subjectData}
              quizTypeAnalyticsSelection={props.quizTypeAnalyticsSelection}
              classroomAnalyticsData={props.classroomAnalyticsData}
              typeFilter={props.typeFilter}
              onChangeFilter={props.onChangeFilter}
              startDateFilter={props.startDateFilter}
              endDateFilter={props.endDateFilter}
              handleCloseDateDialogue={props.handleCloseDateDialogue}
              handleOpenDateDialogue={props.handleOpenDateDialogue}
              handleCloseWithConfirm={props.handleCloseWithConfirm}
              open={props.open}
              onChangeEndDateFilter={props.onChangeEndDateFilter}
              onChangeStartDateFilter={props.onChangeStartDateFilter}
              handleDatePickerClick={props.handleDatePickerClick}
            />
          ) : (
            ""
          )}
        </>
      ),
      icon: DashboardIcon,
    },
    {
      label: "Manage ",
      value: 2,
      body: (
        <>
          {state.DashboardTabsState === 2 ? (
            <Tabs value={manageSubTab} className="w-full">
              <TabsHeader
                className=" w-[30%] overflow-hidden  h-[60px] flex justify-start bg-[#6750A414] sm:overflow-auto"
                indicatorProps={{
                  className: "bg-[#6750A414] shadow-none !text-gray-900 text-[#1D192B]",
                }}
              >
                <Tab value="student" onClick={() => setManageSubTab("student")}>
                  Manage Students
                </Tab>
                <Tab value="teacher" onClick={() => setManageSubTab("teacher")}>
                  <div className=" inline-block">
                    {/* The Icon */}
                    Manage Teachers
                    {/* The Red Dot */}
                    <span
                      style={{
                        display:
                          props.classroomData.teacher_data.some((teacher) => teacher.status === 0) && props.classroomData.is_parent === true
                            ? " "
                            : "none",
                      }}
                      className="absolute  w-2 h-2 bg-red-500 rounded-full"
                    />
                  </div>
                </Tab>
              </TabsHeader>

              <TabsBody>
                <TabPanel value="student">
                  <TeacherStudentTabBody
                    classroomData={props.classroomData}
                    studentData={props.studentData}
                    removeStudent={props.removeStudent}
                    selectedOption={props.selectedOption}
                    handleChange={props.handleChange}
                    changeStudentEnrollmentStatus={props.changeStudentEnrollmentStatus}
                    bannerObject={props.bannerObject}
                  />
                </TabPanel>
                <TabPanel value="teacher">
                  <TeacherTable teacher_data={props.classroomData.teacher_data} is_parent={props.classroomData.is_parent} />
                </TabPanel>
              </TabsBody>
            </Tabs>
          ) : (
            ""
          )}
        </>
      ),
      icon: StudentIcon,
    },
    {
      label: "Manage Retrieval",
      value: 3,
      body: (
        <>
          {state.DashboardTabsState === 3 ? (
            <TeacherRetrievalTabBody
              analyticsData={props.analyticsData ? props.analyticsData : props.individualAnalyticsData}
              setStudentId={props.setStudentId}
              studentId={props.studentId}
              data={props.data2}
              classroomData={props.classroomData}
            />
          ) : (
            ""
          )}
        </>
      ),
      icon: MarkbookIcon,
    },
  ];

  const handleClick = (selectedTab) => {
    if (selectedTab === 1) {
      amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
      amplitude.getInstance().logEvent("Dashboard");
    }
    if (selectedTab === 2) {
      amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
      amplitude.getInstance().logEvent("Students");
    }
    if (selectedTab === 3) {
      amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
      amplitude.getInstance().logEvent("Markbook");
    }
    state.setDashboardTabsState(selectedTab);
  };

  return (
    <Tabs id="custom-animation" value={state.DashboardTabsState} className="md:w-[90%] ">
      <div className="md:ml-[30px]">
        <center>
          <TabsHeader
            className=" w-[100%] h-[60px] flex md:justify-center bg-[#6750A414] sm:overflow-auto"
            indicatorProps={{
              className: "bg-[#6750A414] shadow-none !text-gray-900 text-[#1D192B]",
            }}
          >
            {data.map(({ label, value, icon }) => (
              <div className="!z-0" onClick={() => handleClick(value)} key={value}>
                <Tab value={value} icon={icon} className="w-[100%]" style={{ marginRight: "40px" }}>
                  <div className="flex  items-center">
                    <div className="relative inline-block">
                      {/* The Icon */}
                      <img src={icon} className="h-[20px] w-[20px]" alt={`${label} icon`} />
                      {/* The Red Dot */}
                      <span
                        style={{
                          display:
                            value === 2 &&
                            props.classroomData.teacher_data.some((teacher) => teacher.status === 0) &&
                            props.classroomData.is_parent === true
                              ? " "
                              : "none",
                        }}
                        className="absolute top-3 right-0 w-2 h-2 bg-red-500 rounded-full"
                      />
                    </div>
                    {/* Label below the icon */}
                    <div className="mt-1 ml-[13px]">{label}</div>
                  </div>
                </Tab>
              </div>
            ))}
          </TabsHeader>
        </center>
      </div>

      <TabsBody animate={{}}>
        {data.map(({ value, body }) => (
          <TabPanel key={value} value={value}>
            {body}
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
}

export default TeacherHomeTabs;
