import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Link, useNavigate } from "react-router-dom";
import amplitude from "amplitude-js";
import TextField from "@material-ui/core/TextField";
import appContext from "../context/appContext";
function ForgotPassword({ handleForgetPassword }) {
  const [passwordCriteraMatch, setPasswordCriteraMatch] = React.useState(true);
  const [confirmPasswordCriteraMatch, setConfirmPasswordCriteraMatch] =
    React.useState(true);
  const [passwordHelperText, setPasswordHelperText] = React.useState("");
  const [confirmPasswordHelperText, setConfirmPasswordHelperText] =
    React.useState("");

  React.useEffect(() => {
    try {
      amplitude.getInstance().setUserId(null);
      if (state.loginState === 1) {
        amplitude.getInstance().logEvent("FPP-Page View-Student");
      } else {
        amplitude.getInstance().logEvent("FPP-Page View-Student-Teacher");
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  const [forgotPasswordStep, setForgotPasswordStep] = useState(0);
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [otp, setOtp] = React.useState("");
  const navigate = useNavigate();
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const state = useContext(appContext);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  const submitForgotPasswordRequest = async (e) => {
    if (credentials.email.length <= 0) {
      alert("Enter valid Email or password");
      return;
    }
    e.preventDefault();

    const url =
      state.loginState === 1
        ? "/forgot_password_request_student"
        : "/forgot_password_request";
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + url,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          email_address: credentials.email,
        }),
      })
      .then((res) => {
        console.log(res);
        setForgotPasswordStep(1);
        amplitude.getInstance().setUserId(credentials.email);
        if (state.loginState === 1) {
          amplitude.getInstance().logEvent("FPP-Submit-Student");
        } else {
          amplitude.getInstance().logEvent("FPP-Submit-Teacher");
        }
      })
      .catch((err) => {
        console.log(err);
        amplitude.getInstance().setUserId(credentials.email);
        if (state.loginState === 1) {
          amplitude.getInstance().logEvent("FPP-Submit-Unsuccessful-Student");
        } else {
          amplitude.getInstance().logEvent("FPP-Submit-Unsuccessful-Teacher");
        }

        if (err.response.status === 404) {
          alert(
            "Account with this email address not exist, Please click on signup to create your account"
          );
        }
      });
  };

  const verifyOtp = (email, one_time_password, e) => {
    e.preventDefault();
    const url = state.loginState === 1 ? "/verify_otp_student" : "/verify_otp";
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + url,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          email_address: email,
          one_time_password: one_time_password,
          class_code: null,
        }),
      })
      .then((res) => {
        console.log(res);
        amplitude.getInstance().setUserId(email);
        if (state.loginState === 1) {
          amplitude.getInstance().logEvent("FPP-OTP-Validated-Student");
        } else {
          amplitude.getInstance().logEvent("FPP-OTP-Validated-Teacher");
        }
        setForgotPasswordStep(2);
      })
      .catch((err) => {
        amplitude.getInstance().setUserId(email);
        if (state.loginState === 1) {
          amplitude.getInstance().logEvent("FPP-OTP-Invalid-Student");
        } else {
          amplitude.getInstance().logEvent("FPP-OTP-Invalid-Teacher");
        }
        if (err.response.status === 401) {
          alert("Invalid OTP entered");
        }
        console.log(err);
        //amplitude.getInstance().setUserId(credentials.email);
        //amplitude.getInstance().logEvent('LoginFailed', "error in login api call for email: " + credentials.email);
      });
  };

  const updatePassword = (e) => {
    var classCode = localStorage.getItem("class_code");
    e.preventDefault();

    if (!credentials.password.match("^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$")) {
      setPasswordHelperText(
        "Password must have atleast 6 characters including alphabets and numbers."
      );
      setPasswordCriteraMatch(false);
      return;
    }
    setPasswordHelperText("");
    setPasswordCriteraMatch(true);

    if (credentials.password !== credentials.confirm_password) {
      setConfirmPasswordHelperText(
        "Confirm password did not match with the password entered"
      );
      setConfirmPasswordCriteraMatch(false);
      return;
    }

    setConfirmPasswordHelperText("");
    setConfirmPasswordCriteraMatch(true);

    const url =
      state.loginState === 1
        ? "/update_web_user_password_student"
        : "/update_web_user_password";
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + url,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          email_address: credentials.email,
          password: credentials.password,
          one_time_password: otp,
          class_code: classCode !== undefined ? classCode : null,
        }),
      })
      .then((res) => {
        console.log(res);
        // if (res.data.access_token === undefined) {
        //   alert("Enter valid Email or password");
        //   amplitude.getInstance().setUserId(credentials.email)
        //   amplitude.getInstance().logEvent('LoginUnSuccessful', "user credentials does not match");
        //   // invalid username or password
        //   return;
        // }
        // if(auth_type===1)
        // {
        //     setSignUpStep(2)
        //     return;
        // }
        localStorage.setItem("access_token", res.data.access_token);
        if (state.loginState === 1) {
          localStorage.setItem("user_type", "3");
        } else {
          localStorage.setItem("user_type", res.data.user_type);
        }
        localStorage.setItem("email_address", credentials.email);
        console.log("DATA:", res.data);
        // amplitude.getInstance().setUserId(localStorage.getItem("email_address"));
        // amplitude.getInstance().logEvent('LoginSuccessful', "user successfully logged in with email: " + credentials.email);
        if (state.loginState === 1) {
          navigate("/student-dashboard");
        } else {
          navigate("/");
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          alert("Unable to update web user password");
        }
        console.log(err);
        //amplitude.getInstance().setUserId(credentials.email);
        //amplitude.getInstance().logEvent('LoginFailed', "error in login api call for email: " + credentials.email);
      });
  };

  return (
    <div>
      {forgotPasswordStep === 0 ? (
        <div className="w-full h-full bg-white centered  p-10  sm:p-5">
          <div className="main-content-div w-[500px] sm:w-[360px] md:w-[520px]">
            <div className="sign-in-label ">
              <p>Welcome to Teepee.ai</p>
            </div>
            {/* <div className="sign-in-sub-label ">
              <p>Please enter the code sent to <br/><b>{credentials.email}</b></p>
            </div>
             */}
            <form>
              <div className="mt-[2px]">
                <label className="email-label">Enter your email address:</label>
                <input
                  type="email"
                  name="email"
                  style={{ height: "45px" }}
                  className="textfield-text border-[1px] border-[#8B8B90] rounded-md w-full px-4 text-black leading-tight "
                  id="username"
                  placeholder="Enter your Username"
                  value={credentials.email}
                  onChange={onChange}
                />
              </div>

              <div className="mt-[20px] ">
                <button
                  type="submit"
                  className="sign-in-button bg-[#A07BEC]  rounded-md hover:bg-black text-[white] hover:text-white font-bold py-2 px-4 w-full h-[45px]"
                  onClick={submitForgotPasswordRequest}
                >
                  Submit
                </button>

                <div
                  style={{
                    fontWeight: "700",
                    fontSize: "14px",
                    textAlign: "center",
                    color: "#101010",
                  }}
                >
                  <p className="mb-[0px]">Or</p>
                </div>

                <button
                  type="submit"
                  className="create-new-account-text w-[100%]"
                  onClick={() => {
                    navigate("/register");
                    amplitude.getInstance().setUserId(null);
                    if (state.loginState === 1) {
                      amplitude
                        .getInstance()
                        .logEvent("FPP-Create new account-Student");
                    } else {
                      amplitude
                        .getInstance()
                        .logEvent("FPP-Create new account-Teacher");
                    }
                  }}
                >
                  Create new account
                </button>

                <div className="jess_footer_sm left-[5px] md:hidden sm:block mb-[20px]">
                  <div style={{ color: "black" }}>
                    © Teepee.ai. All rights reserved
                  </div>
                  <div>
                    <a
                      href="/about_us"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-black"
                      style={{ float: "left" }}
                    >
                      About
                    </a>
                  </div>
                  <a
                    href="/privacy-policy.html"
                    className="text-black ml-[30px]"
                    style={{ float: "left" }}
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : forgotPasswordStep === 1 ? (
        <div className="w-full h-full bg-white centered  p-10  sm:p-5">
          <div className="main-content-div w-[500px] sm:w-[360px] md:w-[520px]">
            <div className="sign-in-label ">
              <p>Welcome to Teepee.ai</p>
            </div>
            <div className="sign-in-sub-label ">
              <p>
                Please enter the code sent to <br />
                <b>{credentials.email}</b>
              </p>
            </div>
            <div>
              <div className="w-full">
                <form>
                  <div className="mt-[2px]">
                    <MuiOtpInput
                      length={5}
                      value={otp}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="mt-[20px] ">
                    <button
                      type="submit"
                      className="sign-in-button bg-[#A07BEC]  rounded-md hover:bg-black text-[white] hover:text-white font-bold py-2 px-4 w-full h-[45px]"
                      onClick={(e) => verifyOtp(credentials.email, otp, e)}
                    >
                      Verify
                    </button>

                    <div
                      style={{
                        fontWeight: "700",
                        fontSize: "14px",
                        textAlign: "center",
                        color: "#101010",
                      }}
                    >
                      <p className="mb-[0px]">Or</p>
                    </div>

                    <div className="mt-[0px]">
                      <p
                        style={{
                          color: "black",
                          fontSize: "14px",
                          marginBottom: "0px",
                          float: "left",
                          marginRight: "10px",
                        }}
                      >
                        Already have an account?
                        <Link
                          className="sign-in-text"
                          style={{ fontSize: "14px", marginTop: "0px" }}
                          onClick={(e) => {
                            e.preventDefault();
                            handleForgetPassword();
                            amplitude.getInstance().setUserId(null);
                            if (state.loginState === 1) {
                              amplitude
                                .getInstance()
                                .logEvent("FPP- ClickSign in-Student");
                            } else {
                              amplitude
                                .getInstance()
                                .logEvent("FPP- ClickSign in-Teacher");
                            }
                          }}
                        >
                          {" "}
                          Sign in
                        </Link>
                      </p>
                    </div>

                    <div className="jess_footer_sm left-[5px] md:hidden sm:block mb-[20px]">
                      <div style={{ color: "black" }}>
                        © Teepee.ai. All rights reserved
                      </div>
                      <div>
                        <a
                          href="/about_us"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-black"
                          style={{ float: "left" }}
                        >
                          About
                        </a>
                      </div>
                      <a
                        href="/privacy-policy.html"
                        className="text-black ml-[30px]"
                        style={{ float: "left" }}
                      >
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        forgotPasswordStep === 2 && (
          <div className="w-full h-full bg-white centered  p-10  sm:p-5">
            <div className="main-content-div w-[500px] sm:w-[360px] md:w-[520px]">
              <div className="sign-in-label ">
                <p>Welcome to Teepee.ai</p>
              </div>

              <div>
                <div className="w-full">
                  <form id="signup-form">
                    <div>
                      <label className="email-label mt-[13px] ">Password</label>
                      <TextField
                        type="password"
                        name="password"
                        style={{ height: "65px" }}
                        className=" textfield-text border-[#8B8B90] border-[1px] rounded-md w-full text-black leading-tight  focus:shadow-blue-900  "
                        id="password"
                        error={!passwordCriteraMatch}
                        helperText={passwordHelperText}
                        placeholder="Enter password"
                        value={credentials.password}
                        onChange={onChange}
                      />
                    </div>

                    <div>
                      <label className="email-label mt-[13px] ">
                        Confirm Password
                      </label>
                      <TextField
                        type="password"
                        name="confirm_password"
                        style={{ height: "65px" }}
                        className=" textfield-text border-[#8B8B90] border-[1px] rounded-md w-full text-black leading-tight  focus:shadow-blue-900  "
                        id="confirm_password"
                        error={!confirmPasswordCriteraMatch}
                        helperText={confirmPasswordHelperText}
                        placeholder="Enter password again"
                        value={credentials.confirm_password}
                        onChange={onChange}
                      />
                    </div>

                    <div className="mt-[20px] ">
                      <button
                        type="submit"
                        className="sign-in-button bg-[#A07BEC]  rounded-md hover:bg-black text-[white] hover:text-white font-bold py-2 px-4 w-full h-[45px]"
                        onClick={(e) => updatePassword(e)}
                      >
                        Update password
                      </button>

                      <div
                        style={{
                          fontWeight: "700",
                          fontSize: "14px",
                          textAlign: "center",
                          color: "#101010",
                        }}
                      >
                        <p className="mb-[0px]">Or</p>
                      </div>

                      <div className="mt-[0px]">
                        <p
                          style={{
                            color: "black",
                            fontSize: "14px",
                            marginBottom: "0px",
                            float: "left",
                            marginRight: "10px",
                          }}
                        >
                          Already have an account?
                          <Link
                            className="sign-in-text"
                            style={{ fontSize: "14px", marginTop: "0px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              handleForgetPassword();
                              amplitude.getInstance().setUserId(null);
                              amplitude
                                .getInstance()
                                .logEvent("FPP- ClickSign in");
                            }}
                          >
                            {" "}
                            Sign in
                          </Link>
                        </p>
                      </div>

                      <div className="jess_footer_sm left-[5px] md:hidden sm:block mb-[20px]">
                        <div style={{ color: "black" }}>
                          © Teepee.ai. All rights reserved
                        </div>
                        <div>
                          <a
                            href="/about_us"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-black"
                            style={{ float: "left" }}
                          >
                            About
                          </a>
                        </div>
                        <a
                          href="/privacy-policy.html"
                          className="text-black ml-[30px]"
                          style={{ float: "left" }}
                        >
                          Privacy Policy
                        </a>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
}
export default ForgotPassword;
