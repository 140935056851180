import React from "react";
import ParticipationMetrics from "./ParticipationMetrics";
import AreasToFocusOn from "./AreasToFocusOn";
import CommonMisconceptionsTable from "./CommonMisconceptionsTable";
import StudentsNeedingAttention from "./StudentsNeedingAttention";
import DashboardLabel from "./DashboardLabel";

function ManageRetrievalDashboardTabBody(props) {
  const misconceptions = props.data?.class_analytics_data?.misconception_questions;
  const have_student = props.data?.have_students;
  return (
    <div>
      <ParticipationMetrics data={props.data} />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        <AreasToFocusOn data={props.data} />
        <StudentsNeedingAttention data={props.data} />
      </div>
      {misconceptions && misconceptions.length > 0 ? (
        <CommonMisconceptionsTable data={props.data} />
      ) : (
        <div>
          <DashboardLabel text="Common Misconceptions" />
          <div className="py-[5%] flex justify-center ">
            <div className="md:w-[50%] md:text-[0.83vw] text-center">
              {have_student === false
                ? "Data will appear here once students begin attempting quizzes, highlighting common misconceptions for targeted review."
                : "All questions with more than 70% inaccuracy will appear here for targeted review."}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ManageRetrievalDashboardTabBody;
