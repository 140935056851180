import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import ExitClassroom from "../../images/exit_to_app.png";
import axios from "axios";
import StudentClassroomTabs from "./StudentClassroomTabs";
import ConfirmationDialogue from "../Teacher/confirmationdialogue/ConfirmationDialogue";
import moment from "moment";

function StudentViewClassroom() {
  const location = useLocation();
  const navigate = useNavigate();
  let { studentClassroomData } = location.state ? location.state : "";
  const [classroomData, setClassroomData] = useState();
  const [doThese, setDoThese] = useState([]);
  const [doneThem, setDoneThem] = useState([]);
  const [doTheseRetrieval, setDoTheseRetrieval] = useState([]);
  const [doneThemRetrieval, setDoneThemRetrieval] = useState([]);
  const [masteryLevels, setMasteryLevels] = useState([]);
  // let doThese = JSON.parse(studentClassroomData.do_these);
  // let doneThem = JSON.parse(studentClassroomData.done_them_quiz_list);
  const [openLeaveClassroomDialogue, setOpenLeaveClassroomDialogue] = useState(false);

  const viewQuiz = (quiz) => {
    console.log("called", quiz);
    localStorage.setItem("quiz_taker", 1);
    const quizURL = window.location.origin + "/quiz?id=" + quiz.secret_key;
    window.open(quizURL, "_blank");
  };
  const viewQuizRetrieval = (quiz, quizState) => {
    navigate("/retrieval_quiz", {
      state: {
        quiz: quiz,
        class_id: quiz.classroom_id,
        quizState: quizState,
      },
    });
  };

  const handleOpenLeaveClassroomDialogue = () => {
    setOpenLeaveClassroomDialogue(true);
  };

  const handleCloseLeaveClassroomDialogue = () => {
    setOpenLeaveClassroomDialogue(false);
  };

  const handleCloseLeaveClassroomDialogueWithConfirm = () => {
    leaveClassroom();
    setOpenLeaveClassroomDialogue(false);
  };

  const leaveClassroom = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["authorization"] = token;
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/change_status_of_student", {
        method: "POST",
        student_id_by_teacher: null,
        class_id: studentClassroomData.classroom_id ? studentClassroomData.classroom_id : studentClassroomData,
      })
      .then((response) => {
        console.log(response);
        navigate("/student-dashboard");
      })
      .catch((error) => {
        console.log(error);
        alert("unable to delete the content");
      });
  };

  const handleOnClickDoneThemQuiz = (index) => {
    var selectedQuiz = doneThem[index];

    // var quizResponseId = e.target.value;
    //  var studentName = e.target.name;
    // const quiz = quizData.filter(
    //   (quiz) => quiz.quiz_response_id !== quizResponseId
    // );
    navigate("/quiz-detail-review", {
      state: {
        student_name: selectedQuiz.student_name,
        quiz_response_id: selectedQuiz.quiz_response_id,
        title: selectedQuiz.quiz_title,
        quiz_performace: selectedQuiz.performance,
        quiz_total_marks: selectedQuiz.quiz_total_marks,
        quiz_secret_key: selectedQuiz.secret_key,
        rubric: true,
      },
    });
  };

  useEffect(() => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/student_view_class", {
        method: "POST",
        class_id: studentClassroomData.classroom_id ? studentClassroomData.classroom_id : studentClassroomData,
      })
      .then((response) => {
        // if (response.data.student_status === 2) {
        //   navigate(-1);
        // }
        setClassroomData(response.data);
        setDoThese(JSON.parse(response.data.do_these));
        setDoneThem(JSON.parse(response.data.done_them_quiz_list));
      })
      .catch((error) => {
        console.log(error);
      });
    studentViewClassRetrievalData();
    viewStudentMasteryLevels();
  }, []);

  const studentViewClassRetrievalData = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/student_view_class_retrieval", {
        method: "POST",
        class_id: studentClassroomData.classroom_id ? studentClassroomData.classroom_id : studentClassroomData,
        today_date: moment().format("YYYY-MM-DD HH:mm:ss.SSS"),
      })
      .then((response) => {
        if (response.data.student_status === 2) {
          navigate(-1);
        }
        console.log("Quizzes data: ", response.data);
        setDoTheseRetrieval(response.data.complete_quizzes_list);
        setDoneThemRetrieval(response.data.done_them);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const viewStudentMasteryLevels = () => {
    var token = "Bearer " + localStorage.getItem("access_token");
    axios.defaults.baseURL = process.env.REACT_APP_REST_API_BASE_URL;
    axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
    axios.defaults.headers.post["authorization"] = token;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios
      .post(process.env.REACT_APP_REST_API_BASE_URL + "/get_student_analytics_app", {
        method: "POST",
        classroom_id: studentClassroomData.classroom_id ? studentClassroomData.classroom_id : studentClassroomData,
      })
      .then((response) => {
        setMasteryLevels(response.data.individual_student_analytics);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <ConfirmationDialogue
        title="Leave classroom"
        text="Are you sure you want to leave this classroom?"
        open={openLeaveClassroomDialogue}
        handleCloseWithCancel={handleCloseLeaveClassroomDialogue}
        handleCloseWithConfirm={handleCloseLeaveClassroomDialogueWithConfirm}
      />
      <Sidebar />

      <div className="home sm:ml[25px]  bg-[#F7F2F9] h-[93px] justify-evenly">
        <div className="classroom_name_div sm:w-[50%] md:w-[20%] !overflow-visible">
          {classroomData !== undefined && classroomData.classroom_name}
          <div className="classroom_details_label">Subject:{classroomData !== undefined && classroomData.subject_name}</div>
        </div>
        <div>
          <label className="classroom_details_label"></label>
        </div>
        <div className="flex mb-[13px]">
          <div className="mr-[8px] mt-[auto] mb-[3px]">
            <button onClick={() => handleOpenLeaveClassroomDialogue()}>
              <img src={ExitClassroom}></img>
            </button>
          </div>
          <div className="mt-[auto] mb-[12px] leave-classroom">
            <button onClick={() => handleOpenLeaveClassroomDialogue()}>Leave Classroom</button>
          </div>
        </div>
      </div>
      <div className="sm:ml-[5px] sm:mr-[5px] flex justify-evenly">
        <div className="home sm:ml[25px]">
          <button className="underline teacher_navigation_text flex" onClick={() => navigate(-1)}>
            {"Home > Classroom"}
          </button>
          <div style={{ visibility: "hidden", height: "10px" }}>dasdasd dasd </div>
        </div>
        <div>
          <label className="classroom_details_label"></label>
        </div>
        <div>
          <label className="classroom_details_label"></label>
        </div>
      </div>

      <div className="  sm:ml-0 md:ml-[26%] mb-[40px] mt-[16px] md:w-[60%] sm:w-[95%]">
        <StudentClassroomTabs
          doThese={doThese}
          viewQuiz={viewQuiz}
          doneThem={doneThem}
          handleOnClickDoneThemQuiz={handleOnClickDoneThemQuiz}
          doTheseRetrieval={doTheseRetrieval}
          viewQuizRetrieval={viewQuizRetrieval}
          doneThemRetrieval={doneThemRetrieval}
          masteryLevels={masteryLevels}
        />
      </div>
    </div>
  );
}

export default StudentViewClassroom;
