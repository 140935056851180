import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import amplitude from "amplitude-js";
import axios from "axios";
import ForgotPassword from "./ForgotPassword";
import appContext from "../context/appContext";

function LoginWithEmail(props) {
  const navigate = useNavigate();
  const state = useContext(appContext);

  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [forgotPassword, setForgotPassword] = useState(false);

  const handleForgetPassword = () => {
    setForgotPassword(false);
  };

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const onForgotPasswordClick = () => {
    if (state.loginState === 1) {
      amplitude.getInstance().setUserId(null);
      amplitude.getInstance().logEvent("LP-ForgotPassword-Student");
    } else {
      amplitude.getInstance().setUserId(null);
      amplitude.getInstance().logEvent("LP-ForgotPassword-Teacher");
    }
    setForgotPassword(true);
  };
  const loginUser = async (e) => {
    var classCode = localStorage.getItem("class_code");
    e.preventDefault();

    if (credentials.email.length <= 0) {
      alert("Enter valid Email or password");
      return;
    }
    const url = state.loginState === 1 ? "/student_login" : "/login";
    axios
      .request({
        method: "POST",
        url: process.env.REACT_APP_REST_API_BASE_URL + url,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          email: credentials.email,
          password: credentials.password,
          class_code: classCode !== undefined ? classCode : null,
        }),
      })
      .then((res) => {
        console.log(res);
        if (res.data.access_token === undefined) {
          alert("Enter valid Email or password");
          if (state.loginState === 1) {
            amplitude.getInstance().setUserId(credentials.email);
            amplitude
              .getInstance()
              .logEvent(
                "LP-ManualLoggedIn-Unsuccessful-Student",
                "user credentials does not match"
              );
          } else {
            amplitude.getInstance().setUserId(credentials.email);
            amplitude
              .getInstance()
              .logEvent(
                "LP-ManualLoggedIn-Unsuccessful-Teacher",
                "user credentials does not match"
              );
          }
          // invalid username or password
          return;
        }
        localStorage.setItem("access_token", res.data.access_token);
        if (state.loginState === 1) {
          localStorage.setItem("user_type", "3");
        } else {
          localStorage.setItem("user_type", res.data.user_type);
        }

        localStorage.setItem("email_address", credentials.email);
        if (state.loginState === 1) {
          localStorage.setItem("student_name", res.data.name);
        }
        console.log("DATA:", res.data);
        if (state.loginState === 1) {
          amplitude.getInstance().setUserId(credentials.email);
          amplitude.getInstance().logEvent("LP-ManualLoggedIn-Student");
        } else {
          amplitude.getInstance().setUserId(credentials.email);
          amplitude.getInstance().logEvent("LP-ManualLoggedIn-teacher");
        }

        if (state.loginState === 1) {
          navigate("/student-dashboard");
        } else {
          if (res.data.new_user) {
            navigate("/onboarding");
            return;
          }
          navigate("/");
        }
      })
      .catch((err) => {
        console.log(err);
        if (state.loginState === 1) {
          amplitude.getInstance().setUserId(credentials.email);
          amplitude
            .getInstance()
            .logEvent("LP-ManualLoggedIn-Unsuccessful-Student");
        } else {
          amplitude.getInstance().setUserId(credentials.email);
          amplitude
            .getInstance()
            .logEvent("LP-ManualLoggedIn-Unsuccessful-Teacher");
        }
      });
  };
  return (
    <div>
      {!forgotPassword ? (
        <form>
          <div className="mt-[2px] mb-[20px]">
            <label className="email-label">Enter your email address:</label>
            <input
              type="email"
              name="email"
              style={{ height: "45px" }}
              className="textfield-text border-[1px] border-[#8B8B90] rounded-md w-full px-4 text-black leading-tight  "
              id="username"
              placeholder="Enter your Username"
              value={credentials.email}
              onChange={onChange}
            />
          </div>

          <div>
            <label className="email-label mt-[13px] ">Password:</label>
            <input
              type="password"
              name="password"
              style={{ height: "45px" }}
              className=" textfield-text border-[#8B8B90] border-[1px] rounded-md w-full px-4 text-black leading-tight  focus:shadow-blue-900  "
              id="password"
              placeholder="Enter your Password"
              value={credentials.password}
              onChange={onChange}
            />
          </div>

          <div className="mt-[8px] ">
            <Link
              className="text-[14px] mt-[0px] sign-in-text"
              onClick={() => onForgotPasswordClick()}
            >
              Forgot your password?
            </Link>
            <button
              type="submit"
              className="sign-in-button mt-[8px] bg-[#A07BEC]  rounded-md hover:bg-black text-[white] hover:text-white font-bold py-2 px-4 w-full h-[45px]"
              onClick={loginUser}
            >
              Sign In
            </button>

            <div
              style={{
                fontWeight: "700",
                fontSize: "14px",
                textAlign: "center",
                color: "#101010",
              }}
            >
              <p className="mt-[12px]">Or</p>
            </div>

            <button
              type="submit"
              className="create-new-account-text w-[100%]"
              onClick={() => {
                navigate("/register");
                amplitude.getInstance().setUserId(null);
                amplitude.getInstance().logEvent("FPP-Create new account");
              }}
            >
              Create new account
            </button>

            <div className="jess_footer_sm left-[5px] md:hidden sm:block mb-[20px]">
              <div style={{ color: "black" }}>
                © Teepee.ai. All rights reserved
              </div>
              <div>
                <a
                  href="/about_us"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-black"
                  style={{ float: "left" }}
                >
                  About
                </a>
              </div>
              <a
                href="/privacy-policy.html"
                className="text-black ml-[30px]"
                style={{ float: "left" }}
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </form>
      ) : (
        <ForgotPassword handleForgetPassword={handleForgetPassword} />
      )}
    </div>
  );
}

export default LoginWithEmail;
