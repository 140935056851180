import React from "react";
import StudentDoTheseCard from "./StudentDoTheseCard";
import StudentDoneThemCard from "./StudentDoneThemCard";

function StudentDashbaordTabBody(props) {
  const doThese = props.doThese;
  const doneThem = props.doneThem;

  return (
    <div>
      <div className="classroom_heading">
        <div>
          <label className="dashboard-label" style={{ fontSize: "20px" }}>
            Tasks <div className="teepee-divider"></div>
          </label>
        </div>
      </div>
      {doThese && doThese.length > 0 ? (
        <StudentDoTheseCard doThese={doThese} viewQuiz={props.viewQuiz} quizType={1} />
      ) : (
        <div className="flex justify-center">No tasks available!</div>
      )}
      <hr className="bg-[#CAC4D0]"></hr>
      <div className="classroom_heading">
        <div>
          <label className="dashboard-label" style={{ fontSize: "20px" }}>
            Retrieval Practice <div className="teepee-divider"></div>
          </label>
        </div>
      </div>
      {props.doTheseRetrieval && props.doTheseRetrieval.length > 0 ? (
        <StudentDoTheseCard doThese={props.doTheseRetrieval} viewQuiz={props.viewQuizRetrieval} quizType={2} />
      ) : (
        <div className="flex justify-center">No retrieval quizzes available!</div>
      )}

      <hr className="bg-[#CAC4D0]"></hr>
      <div className="classroom_heading mt-[30px]">
        <p className="classroom_heading1 sm:ml-[25px] md:!ml-0 xs:ml-[25px] xs:text-[16px]">Done them!</p>
      </div>
      {doneThem && props.doTheseRetrieval && (doneThem.length > 0 || props.doneThemRetrieval.length > 0) ? (
        <StudentDoneThemCard
          handleOnClickDoneThemQuiz={props.handleOnClickDoneThemQuiz}
          doneThem={doneThem}
          doneThemRetrieval={props.doneThemRetrieval}
          viewQuizRetrieval={props.viewQuizRetrieval}
        />
      ) : (
        <div className="flex justify-center mt-4">You haven’t completed any quizzes yet. Once you do, they’ll appear here</div>
      )}
    </div>
  );
}

export default StudentDashbaordTabBody;
