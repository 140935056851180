import React from "react";
import DashboardLabel from "./DashboardLabel";
import ArrowForward from "../../../images/arrow_forward.svg";
import Book from "../../../images/book.svg";
import Calendar from "../../../images/calendar.svg";

const WelcomeComponent = ({ haveLesson, onClickSchedule, handleClickPrior }) => {
  return (
    <div>
      {haveLesson !== 1 && (
        <div className="mb-8">
          <DashboardLabel text="Welcome" />
          <div className="ml-6">
            <div className="p-2 text-[16px]">Set up spaced retrieval quizzes for your students with ease: </div>
            <ul className=" space-y-2 text-[16px]">
              <li>
                &#8226; <b>Tell us</b> what topics you've covered or plan to cover.
              </li>
              <li>
                &#8226; <b>Invite your students </b>to join the class—quizzes will be sent to them automatically.
              </li>
              <li>
                &#8226; <b>We'll generate quizzes</b> that reinforce learning at the right time.
              </li>
              <li>
                &#8226; <b>Monitor class and student progress</b> to identify areas needing extra attention.
              </li>
            </ul>
          </div>
        </div>
      )}

      {haveLesson !== 1 && <DashboardLabel text="Start Here" />}
      <div className={`md:flex space-x-8 md:p-4 sm:py-2 ${haveLesson === 1 && "sm:w-[100%] md:w-[50%]"}`}>
        {haveLesson !== 1 && (
          <div
            className="bg-gradient-to-b from-[#7088EE] to-[#818DC1] rounded-2xl pt-4 pl-6 relative w-[50%]"
            onClick={handleClickPrior}
            style={{ cursor: "pointer" }}
          >
            <div className="text-left">
              <h3 className="text-[22px] font-black	 text-white">Covered So Far</h3>
              <p className="mt-2 text-white md:text-[0.95vw] w-[70%]">
                Share the topics you've already covered to tailor quizzes to your students' learning.
              </p>
              <button className="mt-6 py-3 text-white text-[14px] font-medium flex w-full item-center w-auto">
                <div>Choose Topics</div>

                <img className="ml-2" src={ArrowForward} />
              </button>
            </div>

            <div className="absolute bottom-0 right-0">
              <img src={Book} alt="Book Icon" className="sm:hidden md:block " />{" "}
            </div>
          </div>
        )}
        {haveLesson !== 1 && <div className="text-2xl font-semibold text-gray-500 flex items-center">OR</div>}

        <div
          className={`bg-[#EF95BA] rounded-2xl pt-4 pl-6 relative  ${haveLesson !== 1 && "md:w-[50%]"}`}
          onClick={onClickSchedule}
          style={{ cursor: "pointer" }}
        >
          <div className="text-left">
            <h3 className="text-[22px] font-black	 text-white">Plan to Cover</h3>
            <p className="mt-2 text-white md:text-[0.95vw] w-[70%]">
              Plan your teaching schedule, and we’ll sync quizzes to align with your timeline.{" "}
            </p>
            <button className="mt-6 py-3 text-white text-[14px] font-medium flex w-full item-center w-auto">
              <div>Set Schedule</div>

              <img className="ml-2" src={ArrowForward} />
            </button>
          </div>
          <div className="absolute bottom-0 right-0 sm:hidden md:block">
            {" "}
            <img src={Calendar} alt="Calendar Icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeComponent;
